<template>
  <div class="home">
  <div class="">
    <MainHeader />
    <div class="content">
      <div class="main-video-wapper">
      </div>
      <br> <br>
    </div>
      
      <div class="inner philosophy-content">        
        <p><br><br><br></p>
        <p class="Title">スマートなプロセスで<br>未来を創る</p>
        <p class="Title2">私たちは、未来のビジョンを企画の力で実現し<br>
        社会に新たな価値を提供します。</p>
        <br>
        <br><br>
        <br><br>
        <br><br><br><br>
        <br>

      </div>
      </div>
      <h2 class="tit-bg-service" id="service"><img src="../assets/images/tit-top-service.png" alt="SERVICE" width="185" height="75"></h2>
      <div class="inner ">   
        <br><br><br><br>
        <div class="flex">
          <div class="item1">
            <img  class="" src="../assets/images/img-service-consulting.png" alt="プロセスコンサルティング" width="400" height="auto"><br>
            <p>
            ■ プロセスの可視化、分析、改善コンサルティング<br>
            ■ MBSE（Model Based Systems Engineering）の展開<br>
            ■ 会社レベルの経営判断支援（KPI,ロードマップ）<br>
            </p>
          </div>
          <div class="item2">
            <img  class="" src="../assets/images/img-service-aide.png" alt="新たなプロセスの創造" width="400" height="auto"><br>
            <p>
            ■ 産学連携によるアイディエーション活動<br>
            ■ アイデア可視化、検証、普及のための映像コンテンツ作成<br>
            ■ 各種セミナー企画<br>
            </p>
          </div>
        </div>
      </div>
      <br>
      <div class="inner service-pic">
        <img class="" src="../assets/images/img-service.png" alt="サービス概要" width="950" height="auto"><br>
      </div>
      <div class="inner service-content">
        <p>製造業の開発領域に関するDXを加速するため、<br>
開発デジタル化戦略企画、開発プロセス設計<!-- 、シミュレーションプロセス設計 -->に関する各種サービスを提供します。<br>
開発現場に最新のプロセスとソリューションを適用し、開発DXによるイノベーションに貢献します。</p>
<br><br>

        

      </div>
      <h2 class="tit-bg-recruit" id="recruit"><img src="../assets/images/tit-top-recruit.png" alt="RECRUIT" width="188" height="75"></h2>
      <div class="inner recruit-content">
        <br><br><br><br><br>
        <p>私たちは、製造業の開発領域に関するDXを支援するため、コンサルタント、エンジニア、<br>
その他、多様な専門性を持ったメンバーで、業務に取り組んでいます。<br>
私たちの経営理念に共感し、製造業のDXに貢献したい方のご応募をお待ちしています。</p><br>
        <ul>
          <li>
            <h3>
              開発デジタル化戦略企画<br>
コンサルタント
            </h3>
            <p>製品の開発プロセス全体を把握し、開発DX<br>
を実現するグランドデザインを策定します。<br>
また、グランドデザイン実現までの推進企画<br>
を通じて、開発全体のDXを支援します。</p>
          </li>
          <li>
            <h3>
              開発プロセス設計<br>
              エンジニア
            </h3>
            <p>上流設計を意識したTo-Beプロセスの設計、<br>
            プロセスの標準化を推進し、<br>
            開発プロセスの整流化、<br>
            開発のフロントローディングに貢献します。</p>
          </li>
          <!--  
          <li>
            <h3>
              シミュレーションプロセス<br>
              設計エンジニア
            </h3>
            <p>シミュレーションプロセスを開発し、<br>
多角的な視点で定量的データを<br>
蓄積するとともに、<br>
製品性能の向上を実現します。</p>
          </li>
          -->
        </ul>
<MediumButton
          class="btn-recruit-more"
          image-name="btn-txt-more.png"
          image-width="41"
          image-height="25"
          text="MORE"
          linkPath="/recruit"
        />
      </div>
  </div>

<div class="inner news-content">
        <h2><img src="../assets/images/tit-news.png" alt="NEWS" width="125" height="50"></h2>
        <ul class="inner news-content-url">
          <li><span>
              <a href="https://blog.smart-process.co.jp/wp/2025/02/28/%E3%83%96%E3%83%AD%E3%82%B0%E6%9B%B4%E6%96%B0_%E3%83%96%E3%83%BC%E3%82%B9%E7%95%AA%E5%8F%B7%E3%80%81%E3%83%94%E3%83%83%E3%83%81%E6%83%85%E5%A0%B1%E3%82%92%E6%9B%B4%E6%96%B0%E3%81%97%E3%81%BE%E3%81%97/">
              &nbsp;<img src="../assets/images/news-link-icon.png">
              【2025.02.28 fry】<br> 
              東京丸の内で行われるスタートアップイベント参加のブース番号、ピッチ情報を更新しました。<br>
               詳しい概要は弊社SNSにてご確認ください。<br>
              </a>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02yfYNPJbp1SVQ6DBbVi7dBatQxnyUd6jD8x36Q6Jmxhz2vTgBpkw2yspHx4qzuD3Sl&id=100087517794324">
              Facebook </a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7301189623228076032">
              LinkedIn</a>
              </span><br>
              <span>
              <a href="https://blog.smart-process.co.jp/wp/2025/02/26/%E5%87%BA%E5%B1%95%E3%81%AE%E3%81%8A%E7%9F%A5%E3%82%89%E3%81%9B%EF%BC%9A3%E6%9C%881%E6%97%A5%E5%9C%9Fstartupcareerfair2025%EF%BC%A0sushi-tech-square/">
              &nbsp;<img src="../assets/images/news-link-icon.png">
              【2025.02.26 tue】<br> 
              この度3/1東京丸の内で行われるスタートアップイベントに弊社が参加することになりました。<br>
               詳しい概要は弊社SNSにてご確認ください。<br>
              </a>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid028aDR2ssXssjBXGjfuw8STUHUuCYmxhcKzyGkuGGsjccQRkc4p9YWmnQEDn8NyJjol&id=100087517794324">
              Facebook </a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7300371534466953219">
              LinkedIn</a>
              </span><br>
              <span>
              【年末年始休業のお知らせ】<br> 
              誠に勝手ながら、弊社では下記の期間につきまして年末年始休業とさせていただきます。<br>
              期間中はご不便ご迷惑をおかけ致しますが何卒ご了承くださいますようお願い申し上げます。<br>
              年末年始休業期間&nbsp;:&nbsp;2024年12月28日(土)&nbsp;～&nbsp;2025年1月5日(日)<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02eWqYQiJ6SwvYucXNCbir2dTdHb9yjqsVdD72nD1k1TZ7PRsy6XPxv5ZiZJbZMG15l&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7277217867274625025">
              LinkedIn</a>
            </span><br>
            <span>
            【2024.03.05 Tue】<br>
              <a href="https://blog.smart-process.co.jp/wp/2024/03/05/%e7%ac%ac3%e5%9b%9e%e3%80%80%e6%97%a5%e6%9c%ac%e3%81%ae%e8%a3%bd%e9%80%a0%e6%a5%ad%e3%81%ae%e6%9c%aa%e6%9d%a5%e3%82%92%e8%aa%9e%e3%82%8b%e4%bc%9a%e3%80%80%e3%83%bc%e6%9c%aa%e6%9d%a5%e3%82%92%e7%af%89/">
              ブログ更新_Smart Process 主催による第3回 [日本の製造業の未来を語る会：未来を築くディスカッション 共創と未来]
              </a><br>
            </span><br>
            <span>
              【年末年始休業のお知らせ】<br> 
              誠に勝手ながら、弊社では下記の期間につきまして年末年始休業とさせていただきます。<br>
              期間中はご不便ご迷惑をおかけ致しますが何卒ご了承くださいますようお願い申し上げます。<br>
              年末年始休業期間&nbsp;:&nbsp;2023年12月29日(金)&nbsp;～&nbsp;2024年1月8日(月)<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02ZnqqRrn9nhpeeNw5cNfN9qWZhzWcgTaDJrtpJguxsz4Z9Z9B6nTq75XGZBDneZi7l&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7144882085625835520">
              LinkedIn</a>
            </span><br>
            <span>
              【2023.12.12 tue】<br>
              <a href="https://blog.smart-process.co.jp/wp/2023/12/12/%e3%80%8c%e5%83%8d%e3%81%8d%e3%82%84%e3%81%99%e3%81%84%e8%81%b7%e5%a0%b4%e7%92%b0%e5%a2%83%e3%81%a5%e3%81%8f%e3%82%8a%e6%8e%a8%e9%80%b2%e3%83%97%e3%83%a9%e3%83%b3%e3%80%80%ef%bd%82%e3%82%b3%e3%83%bc/">
                ブログ更新_「働きやすい職場環境づくり推進プラン Ｂコース①【介護と仕事の両立推進事業】」を推進しました
              </a><br>
            </span><br>
            <span>
              【2023.09.15 fri】<br>
              <a href="http://blog.smart-process.co.jp/wp/2023/09/15/%e7%ac%ac2%e5%9b%9e%e6%97%a5%e6%9c%ac%e3%81%ae%e8%a3%bd%e9%80%a0%e6%a5%ad%e3%81%ae%e6%9c%aa%e6%9d%a5%e3%82%92%e8%aa%9e%e3%82%8b%e4%bc%9a%ef%bc%9a%e3%83%a2%e3%83%8e%e3%81%a5%e3%81%8f%e3%82%8a%e3%81%ae/">
              ブログ更新_Smart Process 主催による第2回 [日本の製造業の未来を語る会：モノづくりの楽しさの再発見]
              </a><br>
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02aU6pwJmXW32RySBqtQ8kzRC6HJ3vkPBcF3eYKxshGLTtD1dg6o6P1C2AgLQpme18l&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7108257339467563008/">
              LinkedIn</a>
            </span><br>
            <span>
              【夏季休業のお知らせ】<br> 
              誠に勝手ながら、弊社では下記の期間につきまして夏季休業とさせていただきます。<br>
              期間中はご不便ご迷惑をおかけ致しますが、何卒ご了承くださいますようお願い申し上げます。<br>
              夏季休業期間&nbsp;:&nbsp;2023年08月12日(土)&nbsp;～&nbsp;08月20日(日)
            </span><br>
            <span>
              【2023.07.26 wed】<br> 
              <a href="http://blog.smart-process.co.jp/wp/2023/07/25/%e3%80%90smart-process%e3%80%91%e3%80%8c%e3%83%86%e3%83%ac%e3%83%af%e3%83%bc%e3%82%af%e6%9d%b1%e4%ba%ac%e3%83%ab%e3%83%bc%e3%83%ab%e3%80%8d%e5%ae%9f%e8%b7%b5%e4%bc%81%e6%a5%ad%e5%ae%a3%e8%a8%80/">
              ブログ更新_「テレワーク東京ルール」実践企業宣言に登録しました</a>
              <br>&nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid0a4A4rJJe1QcKzcKBTxNuntUFMYLxg6CJLzFYGUeTWKptH4nKnoaaH4zecT2iekp4l&id=100087517794324">
              Facebook</a>
            </span><br>
            <span>
              【2023.06.21 wed】<br>
              <a href="http://blog.smart-process.co.jp/wp/2023/06/21/%e3%80%90smart-process%e3%80%91-%ef%bc%bc%e5%8f%a1%e5%95%93%e5%a4%a7%e5%ad%a6%e5%ae%9f%e8%b7%b5%e6%95%99%e8%82%b2%e3%83%97%e3%83%a9%e3%83%83%e3%83%88%e3%83%95%e3%82%a9%e3%83%bc%e3%83%a0%e5%8d%94/">
              ブログ更新_「叡啓大学実践教育プラットフォーム協議会」に参画しました</a>
              <br>&nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02Z4ujLprqefxd7AAdrR8VK8v3fMWe2Zdh3i8bi4MNkDXSeSg3ASGDkf36wWQEiEvSl&id=100087517794324">
              Facebook</a>
            </span><br>
            <span>
              【2023.06.16 fri】<br> 
              <a href="#SNS">
              &nbsp;<img src="../assets/images/news-link-icon.png">
              HP更新_ホームページの下部にSmart Process公式SNSアカウント一覧を表示しました
              </a>
            </span><br>
            <span>
              【2023.06.12 mon】<br> 
              &nbsp;SNS更新_【記事紹介】「カーボンニュートラルにも多様性を」自動車メーカー集結、日本の技術を世界に発信<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid0qJdCx4oWBBgzNWFuYdd3cLF7JH98xzCgmgzJqWa4fPyKqikRRNooXAqTReAa3PJtl&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7073853330795474945/">
              LinkedIn</a>
            </span><br>
            <span>
              【2023.03.07 tue】<br> 
              <a href="http://blog.smart-process.co.jp/wp/2023/03/07/sdgs/">
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              ブログ更新_SmartProcessのSDGs宣言
              </a>
            </span><br>
            <span>
              【2023.03.06 mon】<br> 
              &nbsp;SNS更新_持続可能な開発目標（SDGs）に関する宣言の表明<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02WZPBQRH9CeUjjFmZ3D4HGjK5VLpwwdgYBPsqZpsGeTXxxw8qJreZV57krmEt18Hzl&id=100087517794324&__cft__[0]=AZVC3SWaWfrw3OkGTpa8mfFVZBIPYO8oCGM8xhiNNG5m-XxNhu-OIktyRi0dIOC_bRK1o3KTeOHLuB8CJ9RTPMUK4QxaF16s0vQ_gbUCHMjUlf-GFsQsV2VfqMBLjuSALOIm_ZGV6clqIO0mIWI1YGX64wSiGeo1yF0GNf2emF6j4_-NWb4tBZ5AGhwx0LOjMuVmiRat7sqmONLgu71fW43q&__tn__=%2CO%2CP-R">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7038314767505117184">
              LinkedIn</a>
            </span><br>
            <span>
              【2023.02.23 thurs】<br> 
              &nbsp;SNS更新_【記事紹介】渋谷のビームス店頭に「まさかの」加賀獅子頭、窮地の伝統工芸が東京で復活<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02WZPBQRH9CeUjjFmZ3D4HGjK5VLpwwdgYBPsqZpsGeTXxxw8qJreZV57krmEt18Hzl&id=100087517794324&__cft__[0]=AZVx_1P24lHUXFr7VF96BTeo0HMd8WeyPYlsxfYIQfIK0WO0OMNhqnhzv-x824JZ8QmgRBAPmZp_MC-H4khPa3oqDUNQAzXrrd6rEjZiuEAR7CLyp_AXoZ1vLGYbDKEz0HU&__tn__=%2CO%2CP-R">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7034442796589600768">
              LinkedIn</a>
            </span><br>
            <span>
              【2023.01.23 mon】<br> 
              &nbsp;SNS更新_【記事紹介】ヤマハ本社工場で見た最新鋭ダイキャストマシン!!100円硬貨の薄さで大物部品を生み出す実力メカ!!<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid0UCX4yjY6VLo2UXMdjLmQoTGJnFVfomfYKrdAUwvcEWEDPsPV8WCw2rDqhM6ezZt6l&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7023166216978534400">
              LinkedIn</a>
            </span><br>
            <span>
              【2023.01.17 tue】<br> 
              &nbsp;SNS更新_【記事紹介】1/16(月)16時「メタバース産業革命～デジタルツインとの融合が生み出す、2030年のビジネス～【各業界の最新動向・事例編】」無料オンラインセミナーを開催<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid02JL3Cr8KLYVYsNwKYY9BRjbfVEnVfUchdjo8yXmrwfv5cZvETWvmRVKMGSMy7qt3xl&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7021358484906532864">
              LinkedIn</a>
            </span><br>
            <span>
              【2022.12.28 wed】<br> 
              &nbsp;SNS更新_【記事紹介】トヨタ自動車が「市民開発」力を強化してDX推進<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid0Vga36ufzT2P1ZsmM6axjKNsmh7iXjLo5UtWyHtKee5u74eQ1J9PeNsLQCb7xaXCQl&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7013816915982786560">
              LinkedIn</a>
            </span><br>
            <span>
              【2022.12.23 mon】<br> 
              &nbsp;SNS更新_年末年始の休業日についてのお知らせ<br>
              &nbsp;<img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid0fp7z2VnavHM1Bp3q3eWJ6oFjPxXqZt6RtA7kN6yASYRz2HHwUhaX3M3XrqGJFDzYl&id=100087517794324">
              Facebook</a> &nbsp;
              <img src="../assets/images/news-link-icon.png"> 
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7011899753277927424">
              LinkedIn</a>
            </span><br>
            <span>
              【2022.11.22 tue】<br> 
              <a href="#videointro">
              &nbsp;<img src="../assets/images/news-link-icon.png">
              HP更新_サービス紹介動画を公開しました。
              </a>
            </span><br>
            <span>
              【2022.10.14 fri】<br>
              <a href="http://blog.smart-process.co.jp/wp/2022/11/16/workshop_1/">
              &nbsp;<img src="../assets/images/news-link-icon.png">
              ブログ更新_Smart Process 主催による第1回 「性能設計エンジニア  教育／ワークショップ」 を執り行いました。
              </a>
            </span>
          </li>
        </ul>
      </div><br><br><br>

  <h2 class="tit-bg-member"><img src="../assets/images/tit-about-member.png" alt="RECRUIT" width="188" height="75"></h2>
  <div class="inner profile-content-2">
	</div>	
  <br><br><br>
		
		<div class="inner member-content">
        <div class="member-list">
          <div class="member-detail">
            <ul>
              <li><img src="../assets/images/img-member-1.jpg" alt="" width="310.5" height="376.5"></li>
            </ul>
          </div>
			
          <div class="member-detail-2">
          <br>
          <ul>
              <li><span class="position">CEO</span><br>
              <span class="name-kana">NISHIOKA , Ryota</span><br>
              <span class="name-kanji">西岡 良太</span></li>
            </ul>
          <p>
          工学系大学院卒業後、<br>
          ソフトウェア会社、自動車OEMでの経験を経て、<br>
          Executive MBA在籍中に株式会社Smart Processを設立。<br>
          代表取締役に就任。<br>
          業務改革コンサルを軸に新しいビジネスにも挑戦。<br>
          日本のものづくり産業の発展を目指しています。<br>
          </p>
          </div>
        </div>
    </div>

    <div class="inner member-content">
        <div class="member-list">
          <div class="member-detail">
            <ul>
              <li><img src="../assets/images/img-member-5.jpg" alt="" width="201" height="251"></li>
              <li><span class="position"></span><br>
              <span class="name-kana">AOYAMA , Tomohiro</span><br>
              <span class="name-kanji">青山 知弘</span></li>
            </ul>
            <p>工学系大学院卒業後、自動車OEMに入社。<br>
車両量産開発のエクステリア性能評価、設計開発DX改革研究テーマの<br>
現在は、DXコンサルタントとして自動車OEM様やサプライヤ様の<br>
業務効率化プロジェクトをご支援させて頂いております。<br><br><br>
</p>
          </div>
          <div class="member-detail">
            <ul>
              <li><img src="../assets/images/img-member-2.jpg" alt="" width="201" height="251"></li>
              <li><span class="position"></span><br>
              <span class="name-kana">KATAYAMA , Kazuko</span><br>
              <span class="name-kanji">片山 和子</span></li>
            </ul>
            <p>慶應義塾大学大学院システムデザイン・マネジメント研究科修了。<br> 
システムエンジニアリング、デザイン思考を学び、<br>
実践に活かしている。趣味は海外ドラマ鑑賞、旅行、ペットの猫。<br> 
好きな言葉は、愚行移山。</p><br><br><br><br>
          </div>
          <div class="member-detail">
            <ul>
              <li><img src="../assets/images/img-member-6.jpg" alt="" width="201" height="251"></li>
              <li><span class="position"></span><br>
              <span class="name-kana">MATSUO , Masakazu</span><br>
              <span class="name-kanji">松尾 雅和</span></li>
            </ul>
            <p>設計エンジニアとして、大手自動車の委託業務で要求仕様に応じた<br>
3D-CADモデルを設計・3Dプリンタ導入の立ち上げ支援を推進、<br>
開発効率向上の研究テーマへのアサイン時には、委託管理責任者を務める。<br>
プロジェクトマネジメントを経て、現職に至る。<br>
現在は、プロジェクトマネジメントオフィスの役責として、<br>
製造業のDX開発改革プロジェクトの推進を支援<br><br><br>
</p>
          </div>
          
          <div class="member-detail">
            <ul>
              <li><img src="../assets/images/img-member-4.jpg" alt="" width="201" height="251"></li>
              <li><span class="name-kana">NINOMIYA , Yurie</span><br>
              <span class="name-kanji">二之宮 百合恵</span></li>
            </ul>
            <p>中央大学文学部卒業後、大手家電量販店に入社しコンシェルジェとして<br>
BtoCセールスを担当する。その経験の中で、BtoBセールス、製造業界に<br>
興味を持つ。新たなステップを模索しているところでスタートアップ<br>
ビジネスへの参画を決意。現在は主にバックオフィス業務を担当、<br>
社内サポート全般を行う。趣味は舞台鑑賞、温泉旅行、ドライブ。</p><br><br>
          </div>
        </div>
        <p>他 メンバー7名在籍</p>
      </div>
    




      <div class="inner profile-content">
        <h2><img src="../assets/images/tit-profile.png" alt="PROFILE" width="121" height="49"><span>会社概要</span></h2>
        <dl>
          <dt>会社名</dt>
          <dd>株式会社 Smart Process （英文社名 Smart Process Co.,Ltd.）</dd>
        </dl>
        <dl>
          <dt>代表者</dt>
          <dd>西岡 良太</dd>
        </dl>
        <dl>
          <dt>所在地</dt>
          <dd>〒105 - 7508<br>
東京都港区海岸１－７－１ 東京ポートシティ竹芝８F</dd>
        </dl>
        <dl>
          <dt>業務内容</dt>
          <dd>製造業の開発デジタル化戦略企画<br>
          DX推進に関するプロジェクトマネジメント業務<br>
          開発プロセス設計業務<br>
          <!-- シミュレーションプロセス設計業務<br> -->
          プロセスプラットフォーム開発事業<br>
          AI分析技術開発</dd>
        </dl>
        <dl>
          <dt>設立</dt>
          <dd>2022年4月</dd>
        </dl>
      </div>
    
    <MainFooter />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import MainHeader from '@/components/common/MainHeader.vue';
import MainFooter from '@/components/common/MainFooter.vue';
import MediumButton from '@/components/common/MediumButton.vue';

export default defineComponent({
  components: {
    MainHeader,
    MainFooter,
    MediumButton

},
  setup() {
    const show = ref<boolean>(false);
    const isLoading = ref<boolean>(true);

    const hideLoader = () => {
      isLoading.value = false;
    }
    
    return {
      isLoading,
      hideLoader,
      show
    }
  }
});
</script>

<style scoped lang="stylus">
@import "@/assets/styles/css-loader.css"

.news-links
.main-video-wapper
  position relative
  width 100%

.sub-video
  width 860px
  height auto
  margin-left 15px
  margin-right 0
  padding 0 30px

.sub-video-tit
  margin-left 39px
  
.loader,
.loader-default:after
  position absolute
.loader
  width 100%
  padding-top 52.083333%

.loader.is-active
  background-color rgba(0, 0, 0, 0.5);
video
  width 100%
  height auto
  aspect-ratio 1920 / 1000
  display block
.inner
  width 940px
  margin 50px auto
  padding 0 20px

.home
  background-image url(../assets/images/img-topbg.jpg);
  background-repeat no-repeat

.philosophy-content.inner
  width 700px
  height 850px
  margin 100px auto
  
  
  p
    font-size 20px
    line-height 1.8
    text-align center
    &:nth-child(2)
      font-size 48px
      line-height 1.5
      margin 0.25em 0

.top-main-content
  background-image url(../assets/images/bg-top.jpg);
.tit-bg-service
  position relative
  width 100%
  padding-top 250px
  background-image url(../assets/images/bg-top-service.jpg);
  background-size cover
  background-position center center
  background-repeat no-repeat
  background-color #ccc
  img
    position absolute
    top 10%
    left 5%

.tit-bg-recruit
  position relative
  width 100%
  padding-top 250px
  background-image url(../assets/images/bg-top-recruit.jpg);
  background-size cover
  background-position center center
  background-repeat no-repeat
  background-color #ccc
  
  img
    position absolute
    top 10%
    left 5%

.tit-bg-member
  position relative
  width 100%
  padding-top 250px
  background-image url(../assets/images/bg-top-member.jpg);
  background-size cover
  background-position center center
  background-repeat no-repeat
  background-color #ccc
  img
    position absolute
    top 10%
    left 5%

.service-content
  overflow hidden
  margin 30px auto 80px
  > p
    font-weight bold
    font-size 21px
    margin 0 0 30px 0
  ul
    padding-inline-start 0

  a
    color #000
    li
      list-style none
      width 297px
      height 269px
      padding 20px
      background-image url(../assets/images/bg-btn-service.png);
      background-size cover
      float left
      transition all .1s ease-out
      &:hover
        opacity 0.5

    &:nth-child(1),
    &:nth-child(2)
      li
        margin-right 4px  

    p
      margin 0
      font-size 12px

    h3
      font-size 27px
      line-height 1.3
      margin 0
      margin-top 0.3em
      height 125px

.recruit-content
  overflow hidden
  margin 30px auto 140px
  > p
    font-weight bold
    font-size 21px
    margin 0 0 30px 0
  ul
    padding-inline-start 0
    margin-left 150px
  li
    list-style none
    border solid 1px
    width 290px
    height 230px
    padding 20px
    float left

    &:nth-child(2)
      margin 0 15px

    p
      margin 0
      font-size 12px

  h3
    font-size 20px
    margin 0
    padding-bottom 2.3em
    span
      font-size 10px
      font-weight normal
      display block
      margin-top 0.3em

.link-content
  overflow hidden
  list-style none
  h2
    width 130px
    float left
    margin 0
  ul
    float left
    padding-inline-start 0
    margin 9px 0 9px 95px
  li
    float left
    &:nth-child(2)
      margin 0 20px

    a
      transition all .1s ease-out
      &:hover
        opacity 0.5

.news-content-url
  border 2px solid black
  padding-top 20px 
  padding-bottom 20px 
  padding-left 20px 
  padding-right 20px
  color black
  text-decoration none
  display flex
  flex-direction column
  width 899.989px
  height 605px
  box-shadow 0 0.5px 2px rgba(0,0,0,.8)
  &:hover
    opacity 1
  li
    list-style none
    float left
    padding 0
    font-size 85%
    padding-bottom 10px
    overflow-y scroll
    a
      color #000
      text-decoration none
      &:hover
        opacity 0.5
    img
      width 17px 
      height 17px
    span
      display block
      content "" 
      margin-bottom -12px
      margin-right 15px
      border-bottom 1px dashed gray
      

.btn-philosophy-more
  text-align center
  padding-top 30px

.btn-recruit-more
  clear both
  text-align center
  padding-top 40px

.flex
  display flex

.item1

.item2
  width 450px

.member-content
  margin 60px auto 120px
  > p
    font-weight bold
    font-size 20px

.member-list
  overflow hidden
.member-detail
  float left
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4)
    margin-bottom 0px
  &:nth-child(5)
    clear both
  &:nth-child(odd)
    width 420px
    padding-right 40px
  &:nth-child(even)
    width 480px
  &:nth-child(3),
  &:nth-child(4)
    li
      &:nth-child(2)
        padding-top 25px

  ul
    padding-inline-start 0
    display flex
    justify-content start
    align-items center
    margin 0
    li
      list-style none
      &:nth-child(2)
        padding-left 30px
      .position
        font-size 17px
        font-weight bold
        margin-bottom 12px
		margin-top 10px
        display inline-block
      .name-kana
        font-size 16px
        margin-bottom 5px
        display inline-block
      .name-kanji
        font-size 30px
        font-weight bold
  p
    font-size 12px
    letter-spacing -0.07em
    line-height 1.8

.member-detail-2
  float left
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4)
    margin-bottom 0px
  &:nth-child(5)
    clear both
  &:nth-child(odd)
    width 420px
    padding-right 40px
  &:nth-child(even)
    width 480px
  &:nth-child(3),
  &:nth-child(4)
    li
      &:nth-child(2)
        padding-top 25px

  ul
    padding-inline-start 0
    display flex
    justify-content start
    align-items center
    margin 0
    li
      list-style none
      &:nth-child(2)
        padding-left 30px
      .position
        font-size 17px
        font-weight bold
        margin-bottom 12px
        display inline-block
      .name-kana
        font-size 16px
        margin-bottom 5px
        display inline-block
      .name-kanji
        font-size 28px
        font-weight bold
  p
    font-size 18px
    letter-spacing -0.07em
    line-height 1.8
.profile-content
  overflow hidden
  margin 0 auto 150px
  h2
    border-bottom 1px solid #000
    margin 0
    padding 0 0 40px
    img
      float left
    span
      margin 14px 0 0 30px
      display inline-block
      font-size 20px

  dl
    display flex
    justify-content start
    align-items center
    border-bottom 1px solid #000
    margin 0
    padding 40px 0
    dt
      width 160px
      padding-left 20px
      font-size 20px
      font-weight bold
    dd
      margin-inline-start 0
      font-size 16px
      line-height 1.8      

.profile-content-2
  overflow hidden
  margin 0 auto 0px
  h2
    margin 0
    padding 0 0 40px
    img
      float left
    span
      margin 14px 0 0 30px
      display inline-block
      font-size 20px
  dl
    display flex
    justify-content start
    align-items center
    margin 0
    padding 40px 0
    dt
      width 160px
      padding-left 20px
      font-size 20px
      font-weight bold
    dd
      margin-inline-start 0
      font-size 16px
      line-height 1.8    
.service-pic
  text-align center  
.recruit-bin
  text-align center
.Title
  color white
  font-weight bold
  font-size 80px
.Title2
  color white
  font-weight bold

</style>
